import React from 'react';
import { useAsync } from 'react-async-hook';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import './styles/output.css';

const fetchIsAuthenticated = async () =>
  (
    await fetch(`https://gateway-api-production.ivoy.mx/is-verified`, {
      method: 'GET',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).json();

const Component = ({ language = 'json', codeString = '' }) => {
  return (
    <SyntaxHighlighter language={language} style={vscDarkPlus}>
      {codeString}
    </SyntaxHighlighter>
  );
};

const App = (): JSX.Element => {
  const loginUrl = `https://gateway-api-production.ivoy.mx/login?return_to=${encodeURIComponent(
    window.location.href,
  )}`;
  const logoutUrl = `https://gateway-api-production.ivoy.mx/logout?return_to=${encodeURIComponent(
    window.location.href,
  )}`;
  const isAuthenticated = useAsync(fetchIsAuthenticated, []);

  return (
    <div className="bg-gray-200 p-20 h-screen flex items-center flex-col">
      <h1 className="text-5xl text-black">Gateway test App</h1>

      <div className="mt-10">
        <span className="mr-10">
          {isAuthenticated.loading && 'Loading... 🤔'}
          {isAuthenticated.loading || 'Here you go... 🤓'}
        </span>

        {isAuthenticated.result && (
          <>
            {isAuthenticated.result?.authenticated && (
              <a
                href={logoutUrl}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                target="_self"
              >
                Logout
              </a>
            )}

            {isAuthenticated.result?.authenticated || (
              <a
                href={loginUrl}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                target="_self"
              >
                Try login
              </a>
            )}
          </>
        )}
      </div>

      <div className="w-3/5 mt-10">
        <Component
          codeString={JSON.stringify(
            isAuthenticated.error
              ? isAuthenticated.error.message
              : isAuthenticated.result,
          )}
        />
      </div>
    </div>
  );
};

export default App;
